@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-weight: 400;
	src: url(https://a.poki-cdn.com/fonts/proxima-nova-regular-cyrillic.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/proxima-nova-regular-cyrillic.woff) format('woff');
	unicode-range: U+0400-04FF
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-weight: 400;
	src: url(https://a.poki-cdn.com/fonts/proxima-nova-regular-greek-coptic.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/proxima-nova-regular-greek-coptic.woff) format('woff');
	unicode-range: U+0370-03FF
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-weight: 400;
	src: url(https://a.poki-cdn.com/fonts/proxima-nova-regular-latin.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/proxima-nova-regular-latin.woff) format('woff');
	unicode-range: U+0020-007F, U+00A0-00FF, U+20AC
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-weight: 400;
	src: url(https://a.poki-cdn.com/fonts/proxima-nova-regular-latin-ext-a.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/proxima-nova-regular-latin-ext-a.woff) format('woff');
	unicode-range: U+0100-017F
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-weight: 400;
	src: url(https://a.poki-cdn.com/fonts/proxima-nova-regular-latin-ext-b.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/proxima-nova-regular-latin-ext-b.woff) format('woff');
	unicode-range: U+0180-024F
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-weight: 700;
	src: url(https://a.poki-cdn.com/fonts/proxima-nova-bold-cyrillic.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/proxima-nova-bold-cyrillic.woff) format('woff');
	unicode-range: U+0400-04FF
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-weight: 700;
	src: url(https://a.poki-cdn.com/fonts/proxima-nova-bold-greek-coptic.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/proxima-nova-bold-greek-coptic.woff) format('woff');
	unicode-range: U+0370-03FF
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-weight: 700;
	src: url(https://a.poki-cdn.com/fonts/proxima-nova-bold-latin.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/proxima-nova-bold-latin.woff) format('woff');
	unicode-range: U+0020-007F, U+00A0-00FF, U+20AC
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-weight: 700;
	src: url(https://a.poki-cdn.com/fonts/proxima-nova-bold-latin-ext-a.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/proxima-nova-bold-latin-ext-a.woff) format('woff');
	unicode-range: U+0100-017F
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-weight: 700;
	src: url(https://a.poki-cdn.com/fonts/proxima-nova-bold-latin-ext-b.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/proxima-nova-bold-latin-ext-b.woff) format('woff');
	unicode-range: U+0180-024F
}

@font-face {
	font-display: swap;
	font-family: 'Torus';
	font-weight: 600;
	src: url(https://a.poki-cdn.com/fonts/torus-semibold-latin.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/torus-semibold-latin.woff) format('woff');
	unicode-range: U+0020-007F, U+00A0-00FF, U+20AC
}

@font-face {
	font-display: swap;
	font-family: 'Torus';
	font-weight: 600;
	src: url(https://a.poki-cdn.com/fonts/torus-semibold-latin-ext-a.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/torus-semibold-latin-ext-a.woff) format('woff');
	unicode-range: U+0100-017F
}

@font-face {
	font-display: swap;
	font-family: 'Torus';
	font-weight: 600;
	src: url(https://a.poki-cdn.com/fonts/torus-semibold-latin-ext-b.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/torus-semibold-latin-ext-b.woff) format('woff');
	unicode-range: U+0180-024F
}

@font-face {
	font-display: swap;
	font-family: 'Torus';
	font-weight: 700;
	src: url(https://a.poki-cdn.com/fonts/torus-bold-latin.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/torus-bold-latin.woff) format('woff');
	unicode-range: U+0020-007F, U+00A0-00FF, U+20AC
}

@font-face {
	font-display: swap;
	font-family: 'Torus';
	font-weight: 700;
	src: url(https://a.poki-cdn.com/fonts/torus-bold-latin-ext-a.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/torus-bold-latin-ext-a.woff) format('woff');
	unicode-range: U+0100-017F
}

@font-face {
	font-display: swap;
	font-family: 'Torus';
	font-weight: 700;
	src: url(https://a.poki-cdn.com/fonts/torus-bold-latin-ext-b.woff2) format('woff2'), url(https://a.poki-cdn.com/fonts/torus-bold-latin-ext-b.woff) format('woff');
	unicode-range: U+0180-024F
}
